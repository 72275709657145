export const configDefaultValues = {
  title: window.location.host,
  name: window.location.host,
  logo: "https://ameerpetitstorage.blob.core.windows.net/amitroot/Uploads%5CYourlogo_22f8c74c-cc9d-4209-8377-c3f3b919c727.png",
  favicon: "",
  link: window.location.origin,
  demoAlias: "demo",
  demosAlias: "demos",
  eventAlias: "event",
  eventsAlias: "events",
  courseAlias: "course",
  coursesAlias: "courses",
  startupsAlias: "startups",
  startupAlias: "startup",
  speakersAlias: "speakers",
  speakerAlias: "speaker",
  mentorsAlias: "mentors",
  mentorAlias: "mentor",
  foundersAlias: "founders",
  founderAlias: "founder",
  bloggerAlias: "blogger",
  bloggersAlias: "bloggers",
  internAlias: "intern",
  internsAlias: "interns",
  freelancerAlias: "freelancer",
  freelancersAlias: "freelancers",
  serviceProviderAlias: "service provider",
  serviceProvidersAlias: "service providers",
  partnerAlias: "partner",
  partnersAlias: "partners",
  sponsorAlias: "sponsor",
  sponsorsAlias: "sponsors",
  corporateStartupAlias: "corporate startup",
  corporateStartupsAlias: "corporate startups",
  NRIStartupAlias: "NRI startup",
  NRIStartupsAlias: "NRI startups",
  betaTesterAlias: "beta tester",
  betaTestersAlias: "beta testers",
  codeAIAlias: "Code(AI)",
  myPageAlias: "my page",
  propertyEventAlias: "Property Event",
  propertyEventsAlias: "Property Events",
  propertyAlias: "Property",
  propertiesAlias: "Properties",
  cohortAlias: "cohort",
  cohortsAlias: "cohorts",
  pageAlias: "page",
  pagesAlias: "pages",
  // blogAreas
  blogArea1: "Blog",
  blogArea2: "Article",
  blogArea3: "Post",
  //
  trainersListID: "65712c68a2664a4197cec890",
  fromEmail: "contact@ameerpetit.com",
  countryFilter: "show",
  tagsFilter: "show",
  coursesFilter: "show",
  learningLevelFilter: "show",
  paymentFilter: "show",
  teachingModeFilter: "show",
  ratingsFilter: "show",
  //
  homeNavItem: "show",
  demosNavItem: "show",
  coursesNavItem: "show",
  eventsNavItem: "show",
  propertiesNavItem: "hide",
  reviewsNavItem: "hide",
  corporatesNavItem: "show",
  blogNavItem: "hide",
  contactNavItem: "show",
  codeAINavItem: "show",
  startupsNavItem: "hide",
  // ---
  meetupsItem: "hide",
  eventsItem: "show",
  startupsItem: "show",
  foundersItem: "hide",
  speakersItem: "hide",
  mentorsItem: "hide",
  trainersItem: "show",
  bloggersItem: "hide",
  internsItem: "hide",
  freelancersItem: "hide",
  serviceProvidersItem: "hide",
  partnersItem: "hide",
  sponsorsItem: "hide",
  corporateStartupsItem: "hide",
  NRIStartupsItem: "hide",
  betaTestersItem: "hide",
  //
  adminEmail: "",
  supportEmail: "",
  basicSubscriptionsCharges: "$199",
  proSubscriptionsCharges: "$299",
  masterSubscriptionsCharges: "$399",
  countryTypeFilterAlias: "Country type",
  tagsFilterAlias: "tags",
  coursesFilterAlias: "Courses",
  learningLevelFilterAlias: "Subcategory",
  paymentFilterAlias: "Payment",
  teachingModesFilterAlias: "Teaching modes",
  ratingsFilterAlias: "Ratings",
  homePageRoute: "/",
  defaultRegisterForm: "registerPopup",
  //
  heroSliderHeading: "SEARCH DEMOS COURSES...",
  heroSliderText:
    "Our online courses are built in partnership with technology leaders and are designed to meet industry demands.",
  userReqBtnText: "Contact ",
  codeGeneratorText: "AI Chat",
  codeGeneratorDesc: " Ask Question & Get Answers",
  codeGeneratorPlaceholder: "",
};
